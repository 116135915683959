import request from "../../utils/request";

export const fetchData = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "Website/Home/init",
    method: "get",
    params: query,
  });
};

export const upForward = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "Article/user/Forward",
    method: "get",
    params: query,
  });
};

export const upPv = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "Portal/dynamic/pv",
    method: "get",
    params: query,
  });
};
