<template>
  <div class="fuiler">
    <div class="nav_block">
      <Header class="Header"></Header>
    </div>
    <div class="main">
      <div class="introduce">
        <div class="lamp"></div>
        <div class="title"></div>
        <div class="count">
          是一场基于交叉学科背景的群体智慧实验，<br />
          通过区块链技术保障群体协作的版 权及效益分配，<br />
          让“万人”共同创作文学作品。<br />
          每季将会有一个主题，<br />
          参与人可自行组队在限时日期内完成挑战，<br />
          即可获得丰厚奖励。
        </div>
      </div>
      <div class="title CurrentTitle"></div>
      <router-link
        :to="{
          path: '/mobile/details',
          query: { TitleNumber: Now.TitleNumber, Title: Now.Title },
        }"
      >
        <div
          class="theme"
          :style="'background-image: url(' + Now.ImageUrl + ')'"
        >
          <div class="Time">{{ Now.TitleNumber }}</div>
          <div class="Title">#{{ Now.Title }}#</div>
          <div class="right">
            {{ Now.Explain_one }}<br />
            {{ Now.Explain_two }}
          </div>
        </div>
      </router-link>

      <div class="title LastTitle"></div>

      <div
        class="theme"
        v-for="(item, index) in before"
        :key="index"
        :style="'background-image: url(' + item.ImageUrl + ')'"
      >
        <router-link
          :to="{
            path: '/mobile/browseArticles',
            query: {
              TitleNumber: item.TitleNumber,
              Title: item.Title,
              Grounding: '1',
            },
          }"
        >
          <div class="Time">{{ item.TitleNumber }}</div>
          <div class="Title">#{{ item.Title }}#</div>
          <div class="right">
            {{ item.Explain_one }}<br />
            {{ item.Explain_two }}
          </div>
        </router-link>
      </div>

      <div class="slogan">
        同我们一起共创添书宇宙<br />三驱前传 | 神秘档案馆 | 添空之树
      </div>
    </div>

    <Footer class="Footer"></Footer>
  </div>
</template>

<script>
import Header from "../conponents/Header";
import Footer from "../conponents/Footer";
import { fetchData, upForward } from "../../../api/home/home";

export default {
  data() {
    return {
      Now: {},
      before: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    let query = this.$route.query;
    let puth = query.puth;
    let _id = query._id;
    if (puth == "browseText") {
      this.$router.push({ path: "/mobile/browseText/" + _id });
      upForward({ _id }).then();
    }

    fetchData().then((res) => {
      let Now = res.Now;
      let before = res.before;
      this.Now = Now;
      this.before = before[0];
    });
    // if (this.isMobile()) {
    //   this.$router.push("/mobile/home").catch((error) => error);
    // } else {
    //   this.$router.push("/pc/home").catch((error) => error);
    // }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style scoped lang="scss">
.fuiler {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 100% 100%;

  .nav_block {
    position: relative;
    background-image: url("../../../assets/Image/public/bg.png");
    background-size: 100% 100%;
    width: 100%;
    height: 11rem;
    margin-bottom: 2rem;

    .Header {
      background-image: url("../../../assets/Image/public/header.png");
      background-size: 100% 100%;
      height: 3rem;
    }

    @mixin nav_block {
      position: absolute;
      width: 18rem;
      height: 6rem;
      padding: 0.8rem;
      background-color: #ffffff;
      border-radius: 0.5rem;
      box-shadow: 0 0 15px 1px #78432e;
      font-size: 0.8rem;
      line-height: 1rem;
      top: 6rem;
      text-indent: 1rem;
    }

    .block {
      left: 50%;
      margin-left: -10rem;
      @include nav_block;
    }

    .NavTitle {
      background-image: url("../../../assets/Image/public/NavTitle.png");
      background-size: 100% 100%;
      width: 7rem;
      height: 2rem;
      display: inline-block;
    }
  }

  .main {
    width: 100%;

    .introduce {
      width: 100%;
      height: 16rem;
      .lamp {
        background-image: url("../../../assets/Image/mobile/home/lamp.png");
        background-size: 100% 100%;
        width: 3rem;
        height: 2.5rem;
        margin: 0 auto;
        margin-bottom: 0.6rem;
      }
      .title {
        background-image: url("../../../assets/Image/mobile/home/LampTitle.png");
        background-repeat: no-repeat;
        background-position: center;
        color: #93523a;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        width: 100%;
        height: 2.5rem;
      }
      .count {
        width: 17rem;
        margin: 0 auto;
        text-align: center;
        font-size: 0.7rem;
        line-height: 1.5rem;
      }
    }

    .title {
      width: 100%;
      height: 2.5rem;
      margin-bottom: 0.8rem;
    }
    .CurrentTitle {
      background-image: url("../../../assets/Image/mobile/home/CurrentTitle.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 6rem 2rem;
    }
    .LastTitle {
      background-image: url("../../../assets/Image/mobile/home/LastTitle.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 6rem 2rem;
      margin-top: 0.6rem;
    }

    .theme {
      background-size: 130% 130%;
      background-position: center center;
      //background-image: url("../../../assets/Image/mobile/details/NavBg.png");
      width: 100%;
      height: 11rem;
      position: relative;
      @mixin block {
        width: 100%;
        text-align: center;
        position: absolute;
        color: #ffffff;
        font-size: 1rem;
        font-style: italic;
      }

      .Time {
        @include block;
        top: 2rem;
        font-size: 1.6rem;
      }

      .Title {
        @include block;
        font-size: 1.9rem;
        font-style: italic;
        top: 5rem;
      }

      .right {
        @include block;
        font-size: 0.6rem;
        font-style: italic;
        top: 8rem;
      }
    }

    .down {
      background-image: url("../../../assets/Image/public/down.png");
      width: 2rem;
      height: 1.7rem;
      margin: 0 auto;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      background-size: 100% 100%;
    }

    .slogan {
      font-size: 0.8rem;
      width: 100%;
      text-align: center;
      margin-bottom: 1.3rem;
      margin-top: 1.3rem;
    }
  }

  .Footer {
    background-image: url("../../../assets/Image/public/header.png");
    background-size: 100% 100%;
  }
}
</style>
